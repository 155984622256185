.container {
  /*padding: 0 2rem;*/
}

.pagebg {
    background-size: contain, contain;
    background-image: url('/img/bg@2x.png'), url('/img/bg@2x.png');
    background-position-y: 8rem, bottom;
    background-repeat: no-repeat, no-repeat;
}
